import React, { useCallback, useEffect, useState } from 'react';

import { IReviewQuestionDefaultData } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import { useFieldArray } from 'react-hook-form';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { IconOld } from '~/components/IconOld';
import type { IconNames } from '~/components/IconOld';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';
import ToolTip from '~/components/Tooltip';
import { Errors } from '~/pages/ReviewTemplateSetup/validations';

import {
  Content,
  EqualizeButton,
  Error,
  Form,
  IconPreview,
  InputWrapper,
  NameContainer,
  NameWrapper,
  Percentage,
  RowHeader,
  StyledFooter,
  StyledInput,
  StyledMarker,
  Subtitle,
  SubTitle,
  ThemeWeightRow,
  ThemeWeightsContainer,
  ThemeWeightsWrapper,
  Title,
  ToggleContainer,
  WeightToggleContainer,
  WeightTotal,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { ITheme, IGeneralForm, PopulatedQuestion } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

export interface IThemeWeightProps {
  questions: PopulatedQuestion[];
  formMethods: UseFormReturn<IGeneralForm>;
  themesMap: Record<string, ITheme>;
  sectionState: ISectionState;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  autosave: () => void;
  equalizeWeights: () => void;
}

const ThemeWeights = ({
  questions,
  formMethods,
  themesMap,
  sectionState,
  onSubmit,
  autosave,
  equalizeWeights,
}: IThemeWeightProps) => {
  const { i18n } = useLingui();
  const {
    register,
    control,
    trigger,
    formState: { errors },
    watch,
    setValue,
  } = formMethods;
  const [currentTotal, setCurrentTotal] = useState<number | undefined>();
  const [missingValue, setMissingValue] = useState<number | undefined>();
  const { triedToSubmit, setCurrentSection } = sectionState;
  const getMultiLangString = useMultiLangString();
  const themeWeightsFieldArray = useFieldArray({
    name: 'themeWeights',
    control,
  });
  const themeWeightsWatch = watch('themeWeights');

  useEffect(() => {
    const total = themeWeightsWatch.reduce((acc, field) => acc + field.weight, 0);
    setCurrentTotal(total);
    setMissingValue(100 - total);
  }, [themeWeightsFieldArray, themeWeightsWatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateTemplate = useCallback(
    debounce(() => autosave(), 10_000),
    [],
  );

  useEffect(() => {
    const currentThemes = themeWeightsFieldArray.fields.map((field) => field.themeId);
    const themeIds = Object.keys(themesMap);
    const themeIntersection = intersection(currentThemes, themeIds);
    const hasNewTheme =
      themeIntersection.length !== themeIds.length ||
      themeIntersection.length !== currentThemes.length;
    if (hasNewTheme) {
      equalizeWeights();
      autosave();
    }

    // eslint-disable-next-line
  }, [themesMap]);

  const createLabel = (missingAmount: number): string => {
    if (missingAmount > 0) {
      return `${missingAmount.toFixed(2)}% ${i18n._(t`over`)}`;
    } else {
      return `${(missingAmount * -1).toFixed(2)}% ${i18n._(t`short`)}`;
    }
  };

  const goBack = () => setCurrentSection(1);

  const isDisabled = (requiredQuestions: PopulatedQuestion[], index: number) => {
    return !(
      (requiredQuestions.length > 0 && themeWeightsFieldArray.fields[index]?.enabled) ||
      themeWeightsFieldArray.fields[index]?.weight
    );
  };

  const enableThemeWeight = (
    index: number,
    requiredQuestions: PopulatedQuestion[],
    value: boolean,
  ) => {
    const themeWeight = themeWeightsFieldArray.fields[index];

    themeWeightsFieldArray.update(index, {
      ...themeWeight,
      weight: 0,
      enabled: requiredQuestions.length > 0 ? value : false,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Title>
        <Trans>Theme Weight</Trans>
      </Title>
      <SubTitle>
        <Trans>
          Enable theme weights to use this template in a review to calculate the weighted average
          employee performance. <span>Learn more</span>
        </Trans>
      </SubTitle>
      <Content customizing={watch('isCustomWeight')}>
        <ToggleContainer>
          <Switch
            onChange={() => setValue('isCustomWeight', !watch('isCustomWeight'))}
            checked={watch('isCustomWeight')}
          />
          <Trans>Theme weights</Trans>
        </ToggleContainer>
        {watch('isCustomWeight') && (
          <ThemeWeightsContainer>
            {errors.themeWeights?.some?.((error) => error?.message === Errors.notAllowed) &&
              triedToSubmit && (
                <Banner
                  type={BANNER_TYPES.WARNING}
                  subTitle={i18n._(
                    t`Make sure every theme that is assigned weight contains a minimum of one required rated question.`,
                  )}
                />
              )}
            <ThemeWeightsWrapper>
              <ThemeWeightRow>
                <RowHeader>
                  <Trans>Theme</Trans>
                </RowHeader>{' '}
                <RowHeader>
                  <Trans>Weighted</Trans>
                </RowHeader>
                <RowHeader>
                  <Trans>Weighting</Trans>
                </RowHeader>
              </ThemeWeightRow>
              {themeWeightsFieldArray.fields.map((f, index) => {
                const theme = themesMap[f.themeId];

                const icon = (theme?.icon as IconNames) ?? 'Chatbubbles';
                const colors = (theme?.iconColor ?? '#f7f9ff-#ebf1fe').split('-');
                const registeredField = register(`themeWeights.${index}.weight`, {
                  valueAsNumber: true,
                });
                const fieldError =
                  Object.keys(errors.themeWeights || {})
                    ?.map?.((key) => `themeWeights.${key}.weight`)
                    .find((name) => name === registeredField.name) !== undefined;

                const themeQuestions = questions.filter(({ theme }) => theme === f.themeId);
                const requiredQuestions = themeQuestions.filter(
                  (question) =>
                    (question.settings as IReviewQuestionDefaultData).isAnswerObligated &&
                    (question.settings as IReviewQuestionDefaultData)?.isManualScale === false,
                );
                const themeWeight = themeWeightsFieldArray.fields.find(
                  ({ themeId }) => themeId === f.themeId,
                );

                return (
                  themeWeight && (
                    <ThemeWeightRow key={f.id}>
                      <NameContainer>
                        <IconPreview colors={colors}>
                          <IconOld name={icon} width={23} height={23} />
                        </IconPreview>

                        <NameWrapper>
                          <span className="title">{getMultiLangString(theme?.name ?? '')}</span>
                          <Subtitle>Includes {themeQuestions.length} questions</Subtitle>
                        </NameWrapper>
                        {errors.themeWeights?.[index]?.message === Errors.notAllowed &&
                          triedToSubmit && (
                            <ToolTip
                              disabled={requiredQuestions.length > 0}
                              tooltip={i18n._(
                                t`Weighted themes need a minimum of one rated question that uses the company default rating scale.`,
                              )}
                            >
                              <Error>
                                <Icon icon={ICONS.ERROR_SIGN} size={ICON_SIZES.SMALL} />
                                <span className="message">
                                  <Trans>No required rated question.</Trans>
                                </span>
                              </Error>
                            </ToolTip>
                          )}
                      </NameContainer>

                      <WeightToggleContainer>
                        <ToolTip
                          disabled={requiredQuestions.length > 0}
                          tooltip={i18n._(
                            t`Weighted themes need a minimum of one rated question that uses the company default rating scale.`,
                          )}
                        >
                          <div>
                            <Switch
                              onChange={(value: boolean) =>
                                enableThemeWeight(index, requiredQuestions, value)
                              }
                              checked={!isDisabled(requiredQuestions, index)}
                            />
                          </div>
                        </ToolTip>
                      </WeightToggleContainer>
                      <InputWrapper disabled={isDisabled(requiredQuestions, index)}>
                        <StyledInput
                          error={fieldError && triedToSubmit && !!themeWeight.enabled}
                          type="number"
                          inputMode="numeric"
                          height="38px"
                          width="54px"
                          disabled={isDisabled(requiredQuestions, index)}
                          {...registeredField}
                          onChange={async (e) => {
                            await registeredField.onChange(e);
                            await trigger('themeWeights');
                            debounceUpdateTemplate();
                          }}
                          onBlur={async (e) => {
                            await registeredField.onBlur(e);
                            await trigger('themeWeights');
                          }}
                          step="0.01"
                        />
                        <Percentage>%</Percentage>
                      </InputWrapper>
                    </ThemeWeightRow>
                  )
                );
              })}
              <ThemeWeightRow>
                <EqualizeButton type="button" onClick={() => equalizeWeights()}>
                  <IconOld name="Arrows" width={16} height={16} className="icon" />
                  <Trans>Make all themes even</Trans>
                </EqualizeButton>
                <div />
                <WeightTotal
                  error={
                    errors.themeWeights?.root?.message === Errors.themeWeightsTotalIncorrect &&
                    triedToSubmit
                  }
                >
                  {!isNaN(Number(currentTotal)) && parseFloat(Number(currentTotal).toFixed(2))}%
                  {errors.themeWeights?.root?.message === Errors.themeWeightsTotalIncorrect &&
                    triedToSubmit &&
                    !isNaN(Number(missingValue)) && (
                      <StyledMarker
                        isRight={false}
                        label={createLabel(Number(missingValue))}
                        backgroundColor={COLORS.ERROR}
                      />
                    )}
                </WeightTotal>
              </ThemeWeightRow>
            </ThemeWeightsWrapper>
          </ThemeWeightsContainer>
        )}
      </Content>
      <StyledFooter>
        <Button
          label={i18n._(t`Back`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.TEXT_PRIMARY}
          onClick={goBack}
          icon={ICONS.BACK}
          iconSize={ICON_SIZES.SMALL}
        />
        <Button
          label={i18n._(t`Publish`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.PRIMARY}
          type="submit"
        />
      </StyledFooter>
    </Form>
  );
};

export { ThemeWeights };
