import React, { useEffect, useState } from 'react';

import {
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_RATING_STATUS,
  REVIEW_RATING_TYPE,
} from '@learned/constants';
import {
  IReviewQuestion,
  IReviewRating,
  IReviewTheme,
  ISkill,
  ITask,
  IUserReview,
  IUserReviewQuestion,
  IUserReviewQuestionRating,
  IUserReviewQuestionSkillCategoryV1,
  IUserReviewQuestionSkillCategoryWithJobProfile,
  IUserReviewQuestionText,
} from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';
import { OldSkillQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/OldSkillQuestion';
import { PlanGoalQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/PlanGoalQuestion';
import { RatingQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/RatingQuestion';
import { SkillQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion';
import { TextAreaQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/TextAreaQuestion';
import { IPartialReviewRating } from '~/pages/ReviewGiveFeedback/types';

import {
  CloseButton,
  Content,
  Footer,
  Header,
  QuestionDescription,
  QuestionTitle,
  QuestionWrapper,
  Title,
} from './design';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { IUserReviewQuestionGrouped } from '../../hooks/useReviewRatings';
import { AnswerType, IAnswer } from '../../types';
import {
  IUserReviewQuestionCustomSkillV1Grouped,
  IUserReviewQuestionSkillCategoryGrouped,
  IUserReviewQuestionSkillCategoryV1Grouped,
} from '../../utils';

interface IQuestionEditModalProps {
  userReview?: IUserReview & {
    tasks: ITask[];
    employeeTasksInOtherUserReviews: ITask[];
    userReviewQuestions: IUserReviewQuestion[];
  };
  rating: {
    reviewRating?: AnswerType;
    question: IUserReviewQuestionGrouped & {
      questions?:
        | IUserReviewQuestionCustomSkillV1Grouped
        | IUserReviewQuestionSkillCategoryV1Grouped;
    };
    questionIndex: number;
  };
  updateReviewRating: (
    answer: IAnswer[],
    goalsPlanned?: IUserReview['goalsPlanned'],
    status?: REVIEW_RATING_STATUS,
  ) => void;
  onClose: () => void;
}

export interface IAnswerType {
  questionId: string;
  oldAnswer?: number | null;
  answer?: string | number | null;
  comment?: string;
  isNotApplicable?: boolean;
}

export const QuestionEditModal = ({
  userReview,
  onClose,
  rating,
  updateReviewRating,
}: IQuestionEditModalProps) => {
  const { i18n } = useLingui();
  const [answers, setAnswers] = useState<IAnswer[]>([
    {
      questionId: (rating.question as IReviewQuestion).id,
      answer: rating.reviewRating?.answer,
      comment: rating.reviewRating?.comment,
      isNotApplicable: rating.reviewRating?.answer === null,
    },
  ]);
  const [goalsPlanned, setGoalsPlanned] = useState(userReview?.goalsPlanned);
  const languageState = useLanguageState();
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    setGoalsPlanned(userReview?.goalsPlanned);
  }, [userReview]);

  const { reviewRating, question, questionIndex } = rating;

  const questionDescription = getMultiLangString(question.description || '');
  const themeName = getMultiLangString((question.theme as IReviewTheme)?.name);

  const getQuestionTitle = () => {
    if (
      question.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1 ||
      question.type === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1
    ) {
      return getMultiLangString(
        (
          (question.questions as unknown as IUserReviewQuestionSkillCategoryV1[])?.[0]?.settings
            ?.skill as unknown as ISkill
        )?.name,
      );
    }
    return getMultiLangString(question.name);
  };

  const getQuestionData = (_question: IUserReviewQuestionGrouped) => {
    switch (_question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
      case REVIEW_QUESTION_TYPES.RATING:
        return {
          yourRating: reviewRating as IPartialReviewRating,
          questionNumber: questionIndex.toString(),
          questionTitle: getQuestionTitle(),
          themeName,
          canAnswer: true,
          type: _question.type as REVIEW_QUESTION_TYPES.TEXT | REVIEW_QUESTION_TYPES.RATING,
          question: _question as unknown as IUserReviewQuestionText,
        };
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL: {
        const questionsList = (
          _question as unknown as IUserReviewQuestionSkillCategoryGrouped['skills'][0]
        ).questions.map(
          (
            question: IUserReviewQuestionSkillCategoryWithJobProfile & {
              reviewRatings?: IReviewRating[];
            },
            index: number,
          ) => {
            return {
              question,
              // @ts-ignore
              yourRating: question.reviewRating?.[index],
              questionNumber: questionIndex.toString(),
              questionTitle: getQuestionTitle(),
              themeName,
              canAnswer: true,
              type: question.type as
                | REVIEW_QUESTION_TYPES.SKILL_CATEGORY
                | REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
            };
          },
        );
        return questionsList;
      }
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1:
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1: {
        return {
          question: _question,
          // @ts-ignore
          subQuestions: question.questions.map((q) => ({
            question: q,
            // @ts-ignore
            otherPeerRatings: q.reviewRatings?.filter(
              (rating: IReviewRating) =>
                rating.type === REVIEW_RATING_TYPE.PEER ||
                rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
            ),
            // @ts-ignore
            otherCoachRatings: q.reviewRatings?.filter(
              (rating: IReviewRating) => rating.type === REVIEW_RATING_TYPE.COACH,
            ),
            // @ts-ignore
            otherSelfRating: q.reviewRatings?.find(
              // @ts-ignore
              (rating) => rating.type === REVIEW_RATING_TYPE.SELF,
            ),
          })),
          // @ts-ignore
          yourRating: _question.reviewRating?.[0],
          questionNumber: questionIndex.toString(),
          questionTitle: getQuestionTitle(),
          themeName,
          canAnswer: true,
          type: _question.type as
            | REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1
            | REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1,
        };
      }
    }
  };

  const findSelectedAnswers = (data: IAnswerType[], answers: IAnswer[]) =>
    answers.filter((rating) => data.some((question) => rating.questionId === question.questionId));

  const filterAnswersById = (data: IAnswerType[], answers: IAnswer[]) =>
    answers.filter(
      (rating) =>
        rating.questionId && !data.some((question) => rating.questionId === question.questionId),
    );

  const updateAnswersArray = (data: IAnswerType[], answers: IAnswer[]) => {
    const selectedAnswers = findSelectedAnswers(data, answers);
    return [
      ...filterAnswersById(data, answers),
      ...data.map((answer) => {
        const updatedAnswer =
          selectedAnswers.find((ans) => ans.questionId === answer.questionId) ?? {};
        return { ...updatedAnswer, ...answer };
      }),
    ];
  };

  const updateAnswerSingle = (data: IAnswerType, answers: IAnswer[]) => {
    const selectedAnswer = answers.find((rating) => rating.questionId === data.questionId) ?? {};
    return [
      ...answers.filter((rating) => rating.questionId && rating.questionId !== data.questionId),
      { ...selectedAnswer, ...data },
    ];
  };

  const onChange = (data: IAnswerType | IAnswerType[]) => {
    const updatedAnswers = Array.isArray(data)
      ? updateAnswersArray(data, answers)
      : updateAnswerSingle(data, answers);

    setAnswers(updatedAnswers);
  };

  const getSkillQuestionAnswers = (reviewRatings: IReviewRating[]) => {
    return reviewRatings
      ?.map((reviewRating) => {
        return {
          value: (reviewRating?.answer as number | null) ?? -1,
        };
      })
      .flat();
  };

  const getSkillQuestionAnswersV1 = (reviewRatings: IReviewRating[]) => {
    return reviewRatings
      ?.map((reviewRating) => {
        return (reviewRating?.oldAnswer as number | null) ?? -1;
      })
      .flat()[0];
  };

  const onSave = () => {
    updateReviewRating(
      answers,
      goalsPlanned,
      rating?.reviewRating?.status || REVIEW_RATING_STATUS.PUBLISHED,
    );
  };

  const getQuestion = (question: IUserReviewQuestionGrouped) => {
    const skillTypes: (REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1)[] = [
      REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
      REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
    ];
    const isSkillType = skillTypes.includes(question.type);
    const questionReviewComment = (
      question as IUserReviewQuestion & { reviewRating?: IReviewRating[] }
    )?.reviewRating?.[0]?.comment;
    const reviewComment = reviewRating?.comment;

    const comment = isSkillType ? questionReviewComment ?? undefined : reviewComment ?? undefined;

    switch (question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
        return (
          <TextAreaQuestion
            languageState={languageState}
            questionData={rating.question as unknown as IUserReviewQuestionText}
            onChange={(data: {
              questionId: string;
              answer?: string;
              comment?: string;
              isNotApplicable?: boolean;
            }) => onChange(data)}
            // @ts-ignore
            question={getQuestionData(question)}
            canAnswer
            defaultValues={{
              answer: (reviewRating?.answer as string | null) ?? '',
              isNotApplicable: false,
            }}
          />
        );
      case REVIEW_QUESTION_TYPES.RATING:
        return (
          <RatingQuestion
            languageState={languageState}
            questionData={rating.question as unknown as IUserReviewQuestionRating}
            onChange={(data: {
              questionId: string;
              answer?: number | null;
              comment?: string;
              isNotApplicable?: boolean;
            }) => onChange(data)}
            // @ts-ignore
            question={getQuestionData(question)}
            canAnswer
            defaultValues={{
              answer: (reviewRating?.answer as string | null) ?? '',
              isNotApplicable: false,
              comment,
            }}
            useMultiLangString={useMultiLangString}
          />
        );
      case REVIEW_QUESTION_TYPES.GOAL_PLAN:
        return (
          <PlanGoalQuestion
            userReview={userReview}
            userFrom={userReview?.tasks?.[0]?.userFrom}
            subTypes={question.settings.subTypes}
            goalsPlanned={goalsPlanned}
            setGoalsPlanned={setGoalsPlanned}
          />
        );
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
        return (
          <SkillQuestion
            languageState={languageState}
            onChange={(data: IAnswerType | IAnswerType[]) => onChange(data)}
            // @ts-ignore
            focusAreaQuestions={getQuestionData(question)}
            canAnswer
            defaultValues={{
              // @ts-ignore
              answers: getSkillQuestionAnswers(question.reviewRating),
              isNotApplicable: false,
              comment,
            }}
            useMultiLangString={useMultiLangString}
          />
        );
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1:
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
        return (
          <OldSkillQuestion
            onChange={onChange}
            // @ts-ignore
            question={getQuestionData(question)}
            canAnswer
            languageState={languageState}
            defaultValues={{
              // @ts-ignore
              oldAnswer: getSkillQuestionAnswersV1(question.reviewRating),
              isNotApplicable: false,
              // @ts-ignore
              comment: rating.question.reviewRating?.[0]?.comment || '',
            }}
            useMultiLangString={useMultiLangString}
          />
        );
    }
  };

  return (
    <Modal
      width={750}
      contentStyles={{ padding: '20px 29px 25px 33px' }}
      hideFooter
      isHideHeader
      centerModal
      onClose={onClose}
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>{`Question ${questionIndex} | ${themeName}`}</Trans>
        </Title>
        <CloseButton
          size={ButtonSize.MEDIUM}
          onClick={onClose}
          icon={ICONS.CLOSE}
          variant={ButtonVariant.ICON}
        />
      </Header>
      <Content>
        <QuestionTitle>{getQuestionTitle()}</QuestionTitle>
        <QuestionDescription>
          {questionDescription && <RickTextView html={getMultiLangString(questionDescription)} />}
        </QuestionDescription>
        <QuestionWrapper>{getQuestion(question)}</QuestionWrapper>
      </Content>
      <Footer>
        <Button
          size={ButtonSize.MEDIUM}
          onClick={onClose}
          variant={ButtonVariant.SECONDARY}
          label={i18n._(t`Cancel`)}
        />
        <Button
          size={ButtonSize.MEDIUM}
          onClick={onSave}
          variant={ButtonVariant.PRIMARY}
          label={i18n._(t`Save`)}
        />
      </Footer>
    </Modal>
  );
};
