import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, REVIEW_THEME_STATUS } from '@learned/constants';
import { IReviewQuestion } from '@learned/types';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { ThemeTemplateDashboard } from './components/ThemeTemplateDashboard';
import { DASHBOARD_TYPE } from './constants';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import routes from '~/constants/routes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { checkModuleReviews } from '~/selectors/baseGetters';
import { getCompany } from '~/services/companies';
import {
  getReviewTheme,
  setReviewThemeStatusToDraft,
  updateReviewTheme,
} from '~/services/reviewThemes';

import type { PopulatedReviewTheme } from './types';

const ReviewThemeView = () => {
  const params: Record<string, string | undefined> = useParams();
  const { reviewThemeId } = params;
  const [theme, setTheme] = useState<PopulatedReviewTheme | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  const languageState = useLanguageState();
  const history = useHistory();
  const isModuleReviewEnabled = useSelector(checkModuleReviews);
  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);
  const [differentRatingScales, setDifferentRatingScales] = useState<string[]>([]);

  useEffect(() => {
    if (!isModuleReviewEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewEnabled, history]);

  useEffect(() => {
    let mounted = true;
    const fetchTheme = async () => {
      if (!reviewThemeId) {
        return;
      }
      setIsLoading(true);
      const { data } = await getReviewTheme(reviewThemeId, ['questions']);

      const reviewTheme: PopulatedReviewTheme = data[API_RETURN_FIELDS.REVIEW_THEME];
      if (mounted && reviewTheme) {
        setTheme(reviewTheme);
      }
      setIsLoading(false);
    };
    fetchTheme();

    return () => {
      mounted = false;
    };
  }, [reviewThemeId, forceUpdateCounter]);

  const onPublish = async () => {
    await checkRatingScales();
    if (differentRatingScales.length === 0) {
      const themeToPublish = {
        ...pick(theme, ['name', 'description', 'icon', 'iconColor', 'isHideOnUserDashboard']),
        status: REVIEW_THEME_STATUS.PUBLISHED,
        questions: theme?.questions.map((q: IReviewQuestion) => q.id),
      };

      setIsLoading(true);
      await updateReviewTheme(reviewThemeId, themeToPublish);
      onForceFetch();
    }
  };

  const checkRatingScales = async () => {
    const hasCustomRatingScale = theme?.questions?.some((question) => {
      if ('isManualScale' in question.settings && question.settings.isManualScale) {
        return true;
      }
      return false;
    });
    if (hasCustomRatingScale) {
      const company = await getCompany(theme?.company);
      const companyRatings = company.products.performance.settings.labels.ratingLabels;
      const companyRatingLength = companyRatings.length;
      const ratingLengths: Record<number, number> = {};

      theme?.questions?.forEach((question) => {
        // Skip questions of type GOAL_PLAN or TEXT
        if (question.type === QUESTION_TYPES.GOAL_PLAN || question.type === QUESTION_TYPES.TEXT) {
          return;
        }

        let questionRatingLength: number;
        if ('isManualScale' in question.settings && question.settings.isManualScale) {
          questionRatingLength = question.settings?.options?.length ?? companyRatingLength;
        } else {
          questionRatingLength = companyRatingLength;
        }

        ratingLengths[questionRatingLength] = (ratingLengths[questionRatingLength] || 0) + 1;
      });

      let mostCommonLength: number = companyRatingLength;
      let maxCount = 0;

      for (const [length, count] of Object.entries(ratingLengths)) {
        const parsedLength = parseInt(length, 10);
        if (count > maxCount) {
          mostCommonLength = parsedLength;
          maxCount = count;
        }
      }

      const questionsWithMismatchedScales = theme?.questions?.filter((question) => {
        // Skip questions of type GOAL_PLAN or TEXT
        if (question.type === QUESTION_TYPES.GOAL_PLAN || question.type === QUESTION_TYPES.TEXT) {
          return false;
        }

        const questionRatingLength =
          'isManualScale' in question.settings && question.settings.isManualScale
            ? question.settings?.options?.length ?? companyRatingLength
            : companyRatingLength;

        return questionRatingLength !== mostCommonLength;
      });

      if (questionsWithMismatchedScales && questionsWithMismatchedScales.length > 0) {
        setDifferentRatingScales(
          questionsWithMismatchedScales
            .map((q) => q.id)
            .filter((id): id is string => id !== undefined),
        );
        return false;
      }
    }
  };

  const onEdit = async () => {
    // update to draft
    await setReviewThemeStatusToDraft(reviewThemeId);
    routes.REVIEW_THEME_UPDATE.go({}, { reviewThemeId, isBackPath: true });
  };

  const themePublishValidationErrorCheck = () => {
    return (
      isEmpty(theme?.name[languageState.companyPrimaryLanguage.locale]) ||
      isEmpty(theme?.icon) ||
      isEmpty(theme?.iconColor) ||
      (theme?.questions || [])?.map((q) => q.id).filter((q) => q).length < 1
    );
  };

  const isPublishDisabled = themePublishValidationErrorCheck();

  return (
    <>
      {isModuleReviewEnabled && (
        <ThemeTemplateDashboard
          differentRatingScales={differentRatingScales}
          isLoading={isLoading}
          onPublish={onPublish}
          onEdit={onEdit}
          item={theme}
          isPublishDisabled={isPublishDisabled}
          dashboardType={DASHBOARD_TYPE.THEME}
        />
      )}
    </>
  );
};

export { ReviewThemeView };
