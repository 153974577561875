import React, { useEffect, useState } from 'react';

import { REVIEW_QUESTION_TYPES_V1 } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { CommentSection, NAOption } from './Components';
import { FocusAreaModal } from './Components/FocusAreaModal';
import { FocusAreaQuestionPlaceholder } from './Components/FocusAreaQuestionPlaceholder';
import { FocusAreaSection } from './Components/FocusAreaSection';
import { RatingAnswersV1 } from './Components/RatingAnswersV1';
import { SkillQuestionLabelPlaceholder } from './Components/SkillQuestionLabelPlaceholder';
import { SkillQuestionRatingLabel } from './Components/SkillQuestionRatingLabel';
import {
  AllAnswers,
  Answers,
  DefinitionContainer,
  JobIconWrapper,
  JobNameContainer,
  OldJobSkillQuestionLabel,
  OldSkillQuestionContentWrapper,
  QuestionHeader,
  QuestionWrapper,
  SkillLabelContainer,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';
import { isNotNil } from '~/utils/typePredicates';

import {
  IQuestionCustomSkillV1Data,
  IQuestionDefaultData,
  IQuestionJobProfileV1Data,
} from '../../types';
import { WithComments } from '../QuestionView';

import type {
  IJobProfile,
  IMultiLangString,
  IUserReviewQuestionSkillCategoryV1,
  IUserReviewQuestionDefaultData,
  ISkill,
} from '@learned/types';

export const Text = styled.span<{ hasError?: boolean }>`
  ${(props) =>
    props.hasError
      ? css`
          color: ${COLORS.ACCENT_ERROR};
        `
      : css`
          color: ${COLORS.ICONS_PRIMARY};
        `}
`;

interface IQuestionViewOldSkillQuestionProps {
  languageState: ILanguageStateReturn;
  question: IQuestionCustomSkillV1Data | IQuestionJobProfileV1Data;
  onChange?: (data: {
    questionId: string;
    answer?: number | null; // null could be if we disable NA
    oldAnswer?: number | null; // null could be if we disable NA
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  defaultValues: IQuestionRatingForm;
  canAnswer: boolean;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export interface IQuestionRatingForm extends WithComments {
  answer?: string | null;
  oldAnswer?: string | null;
  isNotApplicable?: boolean;
}

export const OldSkillQuestion = ({
  question,
  defaultValues,
  onChange,
  canAnswer,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
}: IQuestionViewOldSkillQuestionProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const formMethods = useForm<IQuestionRatingForm>({ defaultValues });
  const { control, watch, setValue } = formMethods;
  const notApplicableWatch = watch('isNotApplicable');

  const [selectedJobProfile, setSelectedJobProfile] = useState<IJobProfile | null>(null);
  const [focusAreas, setFocusAreas] = useState<{ label: IMultiLangString }[] | null>(null);
  const $openFocusAreaModal = useBoolState(false);
  const displayAnswers = !!(
    question.subQuestions[0].otherCoachRatings?.length ||
    question.subQuestions[0].otherPeerRatings?.length ||
    question.subQuestions[0].otherSelfRating
  );

  const relevantQuestion = question.subQuestions.at(0)?.question;

  const expectedLevel = (relevantQuestion as unknown as IUserReviewQuestionSkillCategoryV1)
    ?.settings?.expectedLevel;

  const fetchJob = () => {
    setSelectedJobProfile(
      (question.subQuestions[0] as unknown as IUserReviewQuestionSkillCategoryV1).settings
        ?.jobProfile as unknown as IJobProfile,
    );
  };

  useEffect(() => {
    fetchJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchOldAnswer = formMethods.watch('oldAnswer');
  const watchComment = formMethods.watch('comment');

  const options: IUserReviewQuestionDefaultData['options'] =
    question.subQuestions[0]?.question.settings?.options || [];

  const selectedSkillName =
    relevantQuestion?.relevantSkill?.name ||
    (relevantQuestion?.settings?.skill as unknown as ISkill)?.name;
  const selectedSkillDescription =
    relevantQuestion?.relevantSkill?.description ||
    (relevantQuestion?.settings?.skill as unknown as ISkill)?.description;
  const selectedFocusAreas =
    relevantQuestion?.relevantSkill?.focusAreas ||
    (relevantQuestion?.settings?.skill as unknown as ISkill)?.focusAreas;

  const levelLabel = expectedLevel ? options[Number(expectedLevel) - 1]?.label : undefined;
  const selectedFocusArea = selectedFocusAreas
    ?.find(({ level }) => expectedLevel && level === expectedLevel - 1)
    ?.values.map((values) => ({ label: values.name }));

  useEffect(() => {
    setFocusAreas([{ label: question.subQuestions[0]?.question.settings.focusArea.name }]);
  }, [question]);

  const openFocusAreaModal = () => {
    $openFocusAreaModal.on();
  };

  const isExpectedLevel = (index: number) => {
    return index === expectedLevel;
  };

  const isPlaceholderExpectedLevel = () => {
    return !!(
      options.length > 5 &&
      expectedLevel &&
      expectedLevel > 1 &&
      expectedLevel < options.length
    );
  };

  return (
    <QuestionWrapper>
      {canAnswer && relevantQuestion && (
        <>
          <OldSkillQuestionContentWrapper className={notApplicableWatch ? 'disabled' : ''}>
            <QuestionHeader>
              <OldJobSkillQuestionLabel>
                <Text hasError={hasError}>
                  <Trans>How did you/the employee perform on this skill?</Trans>
                </Text>
              </OldJobSkillQuestionLabel>
              {!relevantQuestion?.settings?.isAnswerObligated && (
                <Controller
                  name="isNotApplicable"
                  control={control}
                  render={({ field }) => {
                    return (
                      <NAOption
                        isChecked={notApplicableWatch}
                        onChange={(value: boolean) => {
                          field.onChange(value);
                          onChange?.({
                            questionId: relevantQuestion.id,
                            isNotApplicable: value,
                            comment: watchComment,
                            answer: value ? null : -1, // when NA enable -> set answer to null, if we disable NA -> set answer to -1
                            oldAnswer: value ? null : -1, // save for oldAnswer
                          });
                        }}
                      />
                    );
                  }}
                />
              )}
            </QuestionHeader>

            {question.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1 && (
              <JobNameContainer>
                <JobIconWrapper>
                  <Icon icon={ICONS.CAREER} size={ICON_SIZES.SMALL} />
                </JobIconWrapper>
                <span>{getMultiLangString(selectedJobProfile?.name ?? '')}</span>
              </JobNameContainer>
            )}
            <SkillLabelContainer $isPlaceHolderExpectedLevel={isPlaceholderExpectedLevel()}>
              <Controller
                name="oldAnswer"
                control={control}
                render={({ field }) => (
                  <>
                    {options.length <= 5 ? (
                      options.map((option, index) => {
                        index += 1;
                        const isSelected = !!(field.value && Number(field.value) === index);
                        return (
                          <SkillQuestionRatingLabel
                            // @ts-ignore
                            option={option}
                            key={index}
                            isExpected={isExpectedLevel(index)}
                            isSelected={isSelected}
                            useMultiLangString={useMultiLangString}
                            onChange={() => {
                              setValue('oldAnswer', !isSelected ? index.toString() : null);
                              onChange?.({
                                questionId: relevantQuestion.id,
                                oldAnswer: !isSelected ? index : -1,
                                comment: watchComment,
                              });
                            }}
                          />
                        );
                      })
                    ) : options.length > 5 ? (
                      <>
                        {options.map((_, index) => {
                          index += 1;
                          const isSelected = !!(field.value && Number(field.value) === index);
                          if (index === 1) {
                            return (
                              <SkillQuestionRatingLabel
                                key={index}
                                // @ts-ignore
                                option={options[0]}
                                isSelected={isSelected}
                                isExpected={isExpectedLevel(index)}
                                useMultiLangString={useMultiLangString}
                                onChange={() => {
                                  setValue('oldAnswer', !isSelected ? '1' : null);
                                  onChange?.({
                                    questionId: relevantQuestion.id,
                                    oldAnswer: !isSelected ? index : -1,
                                    comment: watchComment,
                                  });
                                }}
                              />
                            );
                          } else if (index === options.length) {
                            return (
                              <SkillQuestionRatingLabel
                                key={index}
                                // @ts-ignore
                                option={options[options.length - 1]}
                                isSelected={isSelected}
                                isExpected={isExpectedLevel(index)}
                                useMultiLangString={useMultiLangString}
                                onChange={() => {
                                  setValue(
                                    'oldAnswer',
                                    !isSelected ? options.length.toString() : null,
                                  );
                                  onChange?.({
                                    questionId: relevantQuestion.id,
                                    oldAnswer: !isSelected ? index : -1,
                                    comment: watchComment,
                                  });
                                }}
                              />
                            );
                          }
                          return (
                            <SkillQuestionLabelPlaceholder
                              key={index}
                              index={index}
                              // @ts-ignore
                              options={options}
                              // @ts-ignore
                              option={options[index - 1]}
                              isSelected={isSelected}
                              isExpected={isExpectedLevel(index)}
                              useMultiLangString={useMultiLangString}
                              onChange={() => {
                                setValue('oldAnswer', !isSelected ? index.toString() : null);
                                onChange?.({
                                  questionId: relevantQuestion.id,
                                  oldAnswer: !isSelected ? index : -1,
                                  comment: watchComment,
                                });
                              }}
                            />
                          );
                        })}
                      </>
                    ) : null}
                  </>
                )}
              />
            </SkillLabelContainer>
            <DefinitionContainer>
              <span>
                <Trans>Definition</Trans>
              </span>
              : {getMultiLangString(selectedSkillDescription ?? '')}
            </DefinitionContainer>
            {isNotNil(expectedLevel) && focusAreas ? (
              <FocusAreaSection
                level={
                  levelLabel
                    ? {
                        label: levelLabel,
                        focusAreas: selectedFocusArea || [],
                      }
                    : undefined
                }
                useMultiLangString={useMultiLangString}
                openFocusAreaModal={openFocusAreaModal}
              />
            ) : (
              <FocusAreaQuestionPlaceholder openFocusAreaModal={openFocusAreaModal} />
            )}
          </OldSkillQuestionContentWrapper>
          {relevantQuestion?.settings?.isCommentsAllowed && (
            <CommentSection
              formMethods={formMethods}
              isRequired={!notApplicableWatch && relevantQuestion?.settings?.isCommentsObligated}
              onChange={(comment) =>
                onChange?.({
                  questionId: relevantQuestion.id,
                  comment,
                  oldAnswer: watchOldAnswer ? Number(watchOldAnswer) : null,
                })
              }
              hasError={hasCommentError}
            />
          )}
        </>
      )}
      {showOtherRatings && displayAnswers && (
        <AllAnswers>
          <Trans>All answers</Trans>
          <Answers>
            <RatingAnswersV1
              question={question.subQuestions[0] as unknown as IQuestionDefaultData}
              questionData={relevantQuestion as any}
              useMultiLangString={useMultiLangString}
            />
          </Answers>
        </AllAnswers>
      )}
      {$openFocusAreaModal.value && (
        <FocusAreaModal
          options={options}
          focusAreas={selectedFocusAreas ?? []}
          title={i18n._(t`Skill levels: ${getMultiLangString(selectedSkillName ?? '')}`)}
          description={getMultiLangString(selectedSkillDescription ?? '')}
          expectedLevel={expectedLevel}
          onClose={$openFocusAreaModal.off}
          useMultiLangString={useMultiLangString}
        />
      )}
    </QuestionWrapper>
  );
};
