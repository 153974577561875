import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_RATING_TYPE,
  TASK_STATUS,
  TASK_TYPE,
  USER_REVIEW_PEER_TYPE,
} from '@learned/constants';
import { t } from '@lingui/macro';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import { type ISection, SECTION_STATUS, SECTION_TYPE } from '~/components/TableOfContents/types';
import { GRADIENT } from '~/pages/ReviewGiveFeedback/components/Questions/Components/FocusAreaAnswers.constants';

import type {
  IPartialReviewRating,
  IPopulatedReviewTask,
  IPopulatedUserReview,
  IQuestionCustomSkillData,
  IQuestionCustomSkillV1Data,
  IQuestionData,
  IQuestionDefaultData,
  IQuestionJobProfileV1Data,
  IQuestionSkillCategoryData,
  IQuestionSkillCategorySubQuestion,
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionCustomSkillPopulated,
  IUserReviewQuestionCustomSkillV1Populated,
  IUserReviewQuestionJobProfileV1Populated,
  IUserReviewQuestionSkillCategoryGrouped,
  IUserReviewQuestionSkillCategoryPopulated,
  IWithRatings,
  IWithThemeName,
  TransformedQuestion,
} from './types';
import type {
  IMultiLangString,
  IUserReviewQuestion,
  IUserReviewQuestionCustomSkill,
  IUserReviewQuestionGoalPlan,
  IUserReviewQuestionRating,
  IUserReviewQuestionSkillCategory,
  IUserReviewQuestionText,
  WithReplace,
} from '@learned/types';
import type { I18n } from '@lingui/core';

function canSeeButNotAnswerQuestion(
  question: IUserReviewQuestion,
  reviewTask: IPopulatedReviewTask,
) {
  if (reviewTask.type !== TASK_TYPE.REVIEW_COACH_EVALUATE) {
    return false;
  }
  if (question.type !== REVIEW_QUESTION_TYPES.GOAL_PLAN) {
    // @ts-ignore
    return !question.settings?.evaluators?.includes(REVIEW_QUESTION_EVALUATORS.COACH);
  } else {
    return true;
  }
}

function checkMissingAnswerAndComment(
  question: IUserReviewQuestion,
  reviewTask: IPopulatedReviewTask,
  rating: IPartialReviewRating | undefined,
) {
  if (canSeeButNotAnswerQuestion(question, reviewTask)) {
    return false;
  }

  let missingAnswer = false;
  let missingComment = false;

  switch (question?.type) {
    case REVIEW_QUESTION_TYPES.TEXT:
      if (question.settings.isAnswerObligated) {
        missingAnswer = isEmpty(rating?.answer);
      } else {
        missingAnswer = rating?.answer !== null && isEmpty(rating?.answer);
      }
      break;
    case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
    case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
    case REVIEW_QUESTION_TYPES.RATING:
    case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
    case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1:
      if (question.settings.isAnswerObligated) {
        missingAnswer = !isNumber(rating?.answer) || rating!.answer < 1;
      } else {
        missingAnswer =
          rating?.answer !== null && (!isNumber(rating?.answer) || rating!.answer < 1);
      }

      if (
        question?.settings?.isCommentsObligated &&
        rating?.answer !== null &&
        isEmpty(rating?.comment)
      ) {
        missingComment = true;
      }
      break;
  }
  return missingComment || missingAnswer;
}

export const createInitialSections = (
  questions: TransformedQuestion[],
  getMultiLangString: (multiLangString: Record<string, string>) => string,
  reviewTask: IPopulatedReviewTask,
  userReview: IPopulatedUserReview,
  i18n: I18n,
): ISection<IQuestionData>[] => {
  const sections: ISection<IQuestionData>[] = [];
  let sectionNumber = 1;
  let totalQuestionIndex = 0;
  let hasSeenMissingAnswerOrComment = false;
  let hasSeenMissingAnswerOrCommentParent = false;

  questions.forEach((question) => {
    const questionType: REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1 = question.type as any;

    switch (questionType) {
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
        let hasMissingInfoChildren = false;
        const parentIndex = totalQuestionIndex;
        const childSections: number[] = [];

        const castedQuestion =
          questionType === REVIEW_QUESTION_TYPES.CUSTOM_SKILL
            ? (question as IUserReviewQuestionCustomSkillGrouped)
            : (question as IUserReviewQuestionSkillCategoryGrouped);

        const skillQuestions = castedQuestion?.skills?.map((skillQuestion, i) => {
          const subQuestions = skillQuestion?.questions?.map((subQuestion) => {
            const relevantRating = find(
              subQuestion.ratings,
              (rating) =>
                (rating?.createdBy.id === reviewTask?.userTo?.id &&
                  reviewTask.userTo.id !== undefined) ||
                (rating?.createdBy.email === reviewTask?.userTo?.email &&
                  reviewTask.userTo.email !== undefined),
            );
            const allRatings = addNullRatings(subQuestion.ratings, subQuestion, userReview).filter(
              (rating) => rating.createdBy.id !== reviewTask.userTo.id,
            );
            return {
              question: subQuestion,
              yourRating:
                relevantRating ??
                (canSeeButNotAnswerQuestion(subQuestion, reviewTask)
                  ? undefined
                  : {
                      // we have to use -1 to start with because null means user refused to answer
                      answer: -1,
                      comment: null,
                      createdBy: reviewTask.userTo,
                      company: subQuestion.company,
                      createdFor: subQuestion.createdFor,
                      userReview: subQuestion.userReview,
                      userReviewQuestion: subQuestion.id,
                    }),
              otherPeerRatings: allRatings.filter(
                (rating) =>
                  rating.type === REVIEW_RATING_TYPE.PEER ||
                  rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
              ),
              otherCoachRatings: allRatings.filter(
                (rating) => rating.type === REVIEW_RATING_TYPE.COACH,
              ),
              otherSelfRating: allRatings.find((rating) => rating.type === REVIEW_RATING_TYPE.SELF),
            };
          });
          const hasMissingInfo = subQuestions
            .map((subQuestion) =>
              checkMissingAnswerAndComment(
                subQuestion.question,
                reviewTask,
                subQuestion.yourRating,
              ),
            )
            .some((item) => item);
          if (hasMissingInfo) {
            hasMissingInfoChildren = true;
          }
          let newStatus: SECTION_STATUS | undefined;
          if (hasMissingInfo && !hasSeenMissingAnswerOrComment) {
            newStatus = SECTION_STATUS.CURRENT;
            hasSeenMissingAnswerOrComment = true;
          } else if (!hasMissingInfo) {
            newStatus = SECTION_STATUS.DONE;
          }
          totalQuestionIndex++;
          childSections.push(totalQuestionIndex);
          return {
            type: SECTION_TYPE.CHILD,
            title: getMultiLangString(skillQuestion.skillName),
            sectionNumber: `${sectionNumber}.${i + 1}`,
            status: newStatus,
            parentSection: parentIndex,
            data:
              question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL
                ? ({
                    type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
                    subQuestions,
                    questionNumber: `${sectionNumber}.${i + 1}`,
                    questionTitle: getMultiLangString(skillQuestion.skillName),
                    questionDescription: question?.description
                      ? getMultiLangString(question?.description)
                      : '',
                    themeName: getMultiLangString(skillQuestion.themeName),
                    canAnswer: !canSeeButNotAnswerQuestion(subQuestions[0].question, reviewTask),
                  } as IQuestionCustomSkillData)
                : ({
                    type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
                    subQuestions: removeDuplicates(
                      subQuestions as IQuestionSkillCategorySubQuestion[],
                    ),
                    canAnswer: !canSeeButNotAnswerQuestion(subQuestions[0].question, reviewTask),
                    questionNumber: `${sectionNumber}.${i + 1}`,
                    questionTitle: getMultiLangString(skillQuestion.skillName),
                    questionDescription: question?.description
                      ? getMultiLangString(question?.description)
                      : '',
                    themeName: getMultiLangString(skillQuestion.themeName),
                  } as IQuestionSkillCategoryData),
          };
        });

        let newStatus: SECTION_STATUS | undefined;
        if (hasMissingInfoChildren && !hasSeenMissingAnswerOrCommentParent) {
          newStatus = SECTION_STATUS.CURRENT;
          hasSeenMissingAnswerOrCommentParent = true;
        } else if (!hasMissingInfoChildren) {
          newStatus = SECTION_STATUS.DONE;
        }
        totalQuestionIndex++;
        sections.push({
          type: SECTION_TYPE.PARENT,
          title: getMultiLangString(question.name),
          sectionNumber: `${sectionNumber}`,
          status: newStatus,
          parentSection: parentIndex,
          childSections,
        });
        sections.push(...skillQuestions);
        break;
      }
      case REVIEW_QUESTION_TYPES.GOAL_PLAN: {
        totalQuestionIndex++;
        // @ts-ignore
        const castedQuestion = question as IWithRatings<
          WithReplace<
            IUserReviewQuestionGoalPlan,
            { settings: IWithThemeName<IUserReviewQuestionText['settings']> }
          >
        >;
        sections.push({
          type: SECTION_TYPE.BASIC,
          title: getMultiLangString(question.name),
          sectionNumber: `${sectionNumber}`,
          status: SECTION_STATUS.DONE,
          data: {
            type: REVIEW_QUESTION_TYPES.GOAL_PLAN,
            // @ts-ignore
            question: castedQuestion,
            questionNumber: `${sectionNumber}`,
            questionTitle: getMultiLangString(castedQuestion.name),
            questionDescription: castedQuestion?.description
              ? getMultiLangString(castedQuestion?.description)
              : '',
            themeName: getMultiLangString(castedQuestion.settings.themeName),
          },
        });
        break;
      }
      case REVIEW_QUESTION_TYPES.TEXT:
      case REVIEW_QUESTION_TYPES.RATING: {
        const castedQuestion = question as IWithRatings<
          | WithReplace<
              IUserReviewQuestionText,
              { settings: IWithThemeName<IUserReviewQuestionText['settings']> }
            >
          | WithReplace<
              IUserReviewQuestionRating,
              { settings: IWithThemeName<IUserReviewQuestionRating['settings']> }
            >
        >;

        const relevantRating = find(
          castedQuestion.ratings,
          (rating) =>
            (rating?.createdBy.id === reviewTask.userTo.id && reviewTask.userTo.id !== undefined) ||
            (rating?.createdBy.email === reviewTask.userTo.email &&
              reviewTask.userTo.email !== undefined),
        );
        const hasMissingInfo = checkMissingAnswerAndComment(
          castedQuestion,
          reviewTask,
          relevantRating,
        );

        let newStatus: SECTION_STATUS | undefined;
        if (hasMissingInfo && !hasSeenMissingAnswerOrComment) {
          newStatus = SECTION_STATUS.CURRENT;
          hasSeenMissingAnswerOrComment = true;
          hasSeenMissingAnswerOrCommentParent = true;
        } else if (!hasMissingInfo) {
          newStatus = SECTION_STATUS.DONE;
        }
        totalQuestionIndex++;

        const allRatings = addNullRatings(
          castedQuestion.ratings,
          castedQuestion,
          userReview,
        ).filter((rating) => rating.createdBy.id !== reviewTask.userTo.id);
        sections.push({
          type: SECTION_TYPE.BASIC,
          title: getMultiLangString(question.name),
          sectionNumber: `${sectionNumber}`,
          status: newStatus,
          data: {
            type: question.type,
            canAnswer: !canSeeButNotAnswerQuestion(castedQuestion, reviewTask),
            question,
            yourRating:
              relevantRating ??
              (canSeeButNotAnswerQuestion(castedQuestion, reviewTask)
                ? undefined
                : {
                    // we have to use -1 and '' to start with because null means user refused to answer
                    answer: question.type === REVIEW_QUESTION_TYPES.RATING ? -1 : '',
                    comment: null,
                    createdBy: reviewTask.userTo,
                    company: castedQuestion.company,
                    createdFor: castedQuestion.createdFor,
                    userReview: castedQuestion.userReview,
                    userReviewQuestion: castedQuestion.id,
                  }),
            questionNumber: `${sectionNumber}`,
            questionTitle: getMultiLangString(question.name),
            questionDescription: castedQuestion?.description
              ? getMultiLangString(castedQuestion?.description)
              : '',
            themeName: getMultiLangString(castedQuestion.settings.themeName),
            otherPeerRatings: allRatings.filter(
              (rating) =>
                rating.type === REVIEW_RATING_TYPE.PEER ||
                rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
            ),
            otherCoachRatings: allRatings.filter(
              (rating) => rating.type === REVIEW_RATING_TYPE.COACH,
            ),
            otherSelfRating: allRatings.find((rating) => rating.type === REVIEW_RATING_TYPE.SELF),
          } as IQuestionDefaultData,
        });
        break;
      }
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1: {
        const childSections: number[] = [];

        const castedQuestion =
          questionType === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1
            ? (question as IUserReviewQuestionCustomSkillGrouped)
            : (question as IUserReviewQuestionSkillCategoryGrouped);

        const skillQuestions = castedQuestion?.skills?.map((skillQuestion, index) => {
          const subQuestions = skillQuestion?.questions?.map((subQuestion) => {
            const relevantRating = find(
              subQuestion.ratings,
              (rating) =>
                (rating?.createdBy.id === reviewTask.userTo.id &&
                  reviewTask.userTo.id !== undefined) ||
                (rating?.createdBy.email === reviewTask.userTo.email &&
                  reviewTask.userTo.email !== undefined),
            );
            const allRatings = addNullRatings(subQuestion.ratings, subQuestion, userReview).filter(
              (rating) => rating.createdBy.id !== reviewTask.userTo.id,
            );
            const relevantSkill = userReview.backup?.skills[skillQuestion.skillId];

            return {
              question: {
                ...subQuestion,
                relevantSkill,
              },
              yourRating:
                relevantRating ??
                (canSeeButNotAnswerQuestion(subQuestion, reviewTask)
                  ? undefined
                  : {
                      // we have to use -1 to start with because null means user refused to answer
                      answer: -1,
                      comment: null,
                      createdBy: reviewTask.userTo,
                      company: subQuestion.company,
                      createdFor: subQuestion.createdFor,
                      userReview: subQuestion.userReview,
                      userReviewQuestion: subQuestion.id,
                    }),
              otherPeerRatings: allRatings.filter(
                (rating) =>
                  rating.type === REVIEW_RATING_TYPE.PEER ||
                  rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
              ),
              otherCoachRatings: allRatings.filter(
                (rating) => rating.type === REVIEW_RATING_TYPE.COACH,
              ),
              otherSelfRating: allRatings.find((rating) => rating.type === REVIEW_RATING_TYPE.SELF),
            };
          });
          const hasMissingInfo = subQuestions
            .map((subQuestion) =>
              checkMissingAnswerAndComment(
                subQuestion.question,
                reviewTask,
                subQuestion.yourRating,
              ),
            )
            .some((item) => item);

          let newStatus: SECTION_STATUS | undefined;
          if (hasMissingInfo && !hasSeenMissingAnswerOrComment) {
            newStatus = SECTION_STATUS.CURRENT;
            hasSeenMissingAnswerOrComment = true;
          } else if (!hasMissingInfo) {
            newStatus = SECTION_STATUS.DONE;
          }
          totalQuestionIndex++;
          childSections.push(totalQuestionIndex);
          return {
            type: SECTION_TYPE.BASIC,
            title: i18n._(t`How did you/the employee perform on this skill?`),
            sectionNumber: `${sectionNumber}`,
            status: newStatus,
            data: {
              type: REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1,
              subQuestions,
              questionNumber: `${sectionNumber}.${index + 1}`, // we add 1 because we use this for key in question !important (this allow to refresh question on click)
              questionTitle: getMultiLangString(skillQuestion.skillName),
              questionDescription: castedQuestion?.description
                ? getMultiLangString(castedQuestion?.description)
                : '',
              themeName: getMultiLangString(skillQuestion.themeName),
              canAnswer: !canSeeButNotAnswerQuestion(subQuestions[0].question, reviewTask),
            } as IQuestionCustomSkillV1Data | IQuestionJobProfileV1Data,
          };
        });

        sections.push(...(skillQuestions || []));
        break;
      }
    }

    sectionNumber++;
  });

  return sections;
};

export function findFirstUnansweredQuestion(
  questions: TransformedQuestion[],
  reviewTask: IPopulatedReviewTask,
) {
  let totalQuestions = 0;
  if (reviewTask.status === TASK_STATUS.TODO) {
    return -1;
  }

  for (const question of questions) {
    if (
      question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY ||
      question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL
    ) {
      totalQuestions++;
      for (const skillQuestion of question.skills) {
        const hasMissingInfo = skillQuestion.questions
          .map((subQuestion) => {
            const relevantRating = find(
              subQuestion.ratings,
              (rating) =>
                (rating?.createdBy.id === reviewTask.userTo.id &&
                  reviewTask.userTo.id !== undefined) ||
                (rating?.createdBy.email === reviewTask.userTo.email &&
                  reviewTask.userTo.email !== undefined),
            );

            return checkMissingAnswerAndComment(subQuestion, reviewTask, relevantRating);
          })
          .some(Boolean);

        if (hasMissingInfo) {
          return totalQuestions;
        }
        totalQuestions++;
      }
    } else if (
      // @ts-ignore
      question.type === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1 ||
      // @ts-ignore
      question.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1
    ) {
      // we do not need to count question for grouping (as we do in skill-category or custom skill)
      // @ts-ignore
      for (const skillQuestion of question.skills) {
        const hasMissingInfo = skillQuestion.questions
          .map((subQuestion: any) => {
            const relevantRating = find(
              subQuestion.ratings,
              (rating) =>
                (rating?.createdBy.id === reviewTask.userTo.id &&
                  reviewTask.userTo.id !== undefined) ||
                (rating?.createdBy.email === reviewTask.userTo.email &&
                  reviewTask.userTo.email !== undefined),
            );

            return checkMissingAnswerAndComment(subQuestion, reviewTask, relevantRating);
          })
          .some(Boolean);

        if (hasMissingInfo) {
          return totalQuestions;
        }
        totalQuestions++;
      }
    } else {
      const relevantRating = find(
        question.ratings,
        (rating) =>
          (rating?.createdBy.id === reviewTask.userTo.id && reviewTask.userTo.id !== undefined) ||
          (rating?.createdBy.email === reviewTask.userTo.email &&
            reviewTask.userTo.email !== undefined),
      );
      if (checkMissingAnswerAndComment(question, reviewTask, relevantRating)) {
        return totalQuestions;
      }
      totalQuestions++;
    }
  }

  return -1;
}

export function groupQuestionsBasedOnType(userReview: IPopulatedUserReview) {
  const populatedQuestionsMap = userReview.questions.reduce(
    (acc: Record<string, IPopulatedUserReview['questions'][0]>, question) => {
      acc[question.id] = question;
      return acc;
    },
    {},
  );
  type ExtraSettings<T> = {
    skillId: string;
    skillName: IMultiLangString;
    themeName: IMultiLangString;
    questions: IWithRatings<T>[];
  };
  const groupedSkillCategoryQuestions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<string, Record<string, ExtraSettings<IUserReviewQuestionSkillCategoryPopulated>>>,
      questionId,
    ) => {
      const question = populatedQuestionsMap[questionId];
      if (!question || question.type !== REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      const skill = question.settings.skill;
      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][skill] = acc[skillCategory][skill] ?? {
        skillId: skill,
        skillName: question.settings.skillName,
        themeName: question.settings.themeName,
        questions: [],
      };
      acc[skillCategory][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedCustomSkillQuestions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<string, Record<string, ExtraSettings<IUserReviewQuestionCustomSkillPopulated>>>,
      questionId,
    ) => {
      const question = populatedQuestionsMap[questionId];
      if (!question || question.type !== REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      const skill = question.settings.skill;
      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][skill] = acc[skillCategory][skill] ?? {
        skillId: skill,
        skillName: question.settings.skillName,
        themeName: question.settings.themeName,
        questions: [],
      };
      acc[skillCategory][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedCustomSkillV1Questions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<string, Record<string, ExtraSettings<IUserReviewQuestionCustomSkillV1Populated>>>,
      questionId,
    ) => {
      const question = populatedQuestionsMap[
        questionId
      ] as unknown as IUserReviewQuestionCustomSkillV1Populated;

      if (
        !question ||
        (question.type as REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1) !==
          REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1
      ) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      const skill = question.settings.skill;
      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][skill] = acc[skillCategory][skill] ?? {
        skillId: skill,
        skillName: question.settings.skillName,
        themeName: question.settings.themeName,
        questions: [],
      };
      // @ts-ignore
      acc[skillCategory][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedJobProfileV1Questions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<string, Record<string, ExtraSettings<IUserReviewQuestionJobProfileV1Populated>>>,
      questionId,
    ) => {
      const question = populatedQuestionsMap[
        questionId
      ] as unknown as IUserReviewQuestionJobProfileV1Populated;
      const questionType = question?.type as REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1;

      if (!question || questionType !== REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      const skill = question.settings.skill;
      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][skill] = acc[skillCategory][skill] ?? {
        skillId: skill,
        skillName: question.settings.skillName,
        themeName: question.settings.themeName,
        questions: [],
      };
      // @ts-ignore
      acc[skillCategory][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const seenSkillCategoriesForSkillCategory = new Set();
  const seenSkillCategoriesForCustomSkill = new Set();
  const seenSkillCategoriesForCustomSkillV1 = new Set();
  const seenSkillCategoriesForJobProfileV1 = new Set();
  return userReview.userReviewQuestions.reduce((acc: TransformedQuestion[], questionId) => {
    const question = populatedQuestionsMap[questionId];
    if (question && question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
      if (seenSkillCategoriesForSkillCategory.has(question.settings.skillCategory)) {
        return acc;
      } else {
        seenSkillCategoriesForSkillCategory.add(question.settings.skillCategory);
        acc.push({
          type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
          skillCategory: question.settings.skillCategory,
          skills: Object.values(groupedSkillCategoryQuestions[question.settings.skillCategory]),
          name: question.name,
          description: question.description,
          theme: question.theme,
        });
        return acc;
      }
    } else if (question && question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      if (seenSkillCategoriesForCustomSkill.has(question.settings.skillCategory)) {
        return acc;
      } else {
        seenSkillCategoriesForCustomSkill.add(question.settings.skillCategory);
        acc.push({
          type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
          skillCategory: question.settings.skillCategory,
          skills: Object.values(groupedCustomSkillQuestions[question.settings.skillCategory]),
          name: question.name,
          description: question.description,
          theme: question.theme,
        });
        return acc;
      }
    } else if (
      question &&
      (question.type as REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1) ===
        REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1
    ) {
      if (
        seenSkillCategoriesForCustomSkillV1.has(
          (question as unknown as IUserReviewQuestionCustomSkillV1Populated).settings.skillCategory,
        )
      ) {
        return acc;
      } else {
        seenSkillCategoriesForCustomSkillV1.add(
          (question as unknown as IUserReviewQuestionCustomSkillV1Populated).settings.skillCategory,
        );
        acc.push({
          // @ts-ignore
          type: REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1,
          skillCategory: (question as unknown as IUserReviewQuestionCustomSkillV1Populated).settings
            .skillCategory,
          // @ts-ignore
          skills: Object.values(
            groupedCustomSkillV1Questions[
              (question as unknown as IUserReviewQuestionCustomSkillV1Populated).settings
                .skillCategory
            ],
          ),
          name: question.name,
          description: question.description,
          theme: question.theme,
        });
        return acc;
      }
    } else if (
      question &&
      (question.type as REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1) ===
        REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1
    ) {
      if (
        seenSkillCategoriesForJobProfileV1.has(
          (question as unknown as IUserReviewQuestionJobProfileV1Populated).settings.skillCategory,
        )
      ) {
        return acc;
      } else {
        seenSkillCategoriesForJobProfileV1.add(
          (question as unknown as IUserReviewQuestionJobProfileV1Populated).settings.skillCategory,
        );
        acc.push({
          // @ts-ignore
          type: REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1,
          skillCategory: (question as unknown as IUserReviewQuestionJobProfileV1Populated).settings
            .skillCategory,
          // @ts-ignore
          skills: Object.values(
            groupedJobProfileV1Questions[
              (question as unknown as IUserReviewQuestionJobProfileV1Populated).settings
                .skillCategory
            ],
          ),
          name: question.name,
          description: question.description,
          theme: question.theme,
        });
        return acc;
      }
    }
    if (question) {
      acc.push(question);
    }
    return acc;
  }, []);
}

export function* findAllRatings(sections: ISection<IQuestionData>[]) {
  for (const section of sections) {
    if (section.type === SECTION_TYPE.PARENT) {
      continue;
    }
    if (
      section.data?.type === REVIEW_QUESTION_TYPES.TEXT ||
      section.data?.type === REVIEW_QUESTION_TYPES.RATING
    ) {
      yield section.data.yourRating;
    }
    if (section.data?.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      for (const subQuestion of section.data.subQuestions) {
        yield subQuestion.yourRating;
      }
    }
    if (section.data?.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
      for (const subQuestion of section.data.subQuestions) {
        for (const duplicateQuestion of subQuestion.question.settings.duplicateQuestions ?? []) {
          yield duplicateQuestion.yourRating;
        }
        yield subQuestion.yourRating;
      }
    }
    if (
      section.data?.type === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1 ||
      section.data?.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1
    ) {
      // @ts-ignore
      for (const subQuestion of section.data.subQuestions) {
        yield subQuestion.yourRating;
      }
    }
  }
}

function removeDuplicates(subQuestions: IQuestionSkillCategorySubQuestion[]) {
  const newQuestions: Record<string, (typeof subQuestions)[0]> = {};
  const seenFocusArea = new Set();
  subQuestions.forEach((subQuestion) => {
    const focusAreaId = subQuestion.question.settings.focusArea.id;
    if (!seenFocusArea.has(focusAreaId)) {
      seenFocusArea.add(focusAreaId);
      newQuestions[focusAreaId] = subQuestion;
    } else {
      newQuestions[focusAreaId].question.settings.duplicateQuestions =
        newQuestions[focusAreaId].question.settings.duplicateQuestions ?? [];
      newQuestions[focusAreaId].question.settings.duplicateQuestions.push(subQuestion);
    }
  });

  return Object.values(newQuestions);
}

export function getGradient(score: number, total: number) {
  const percentage = (score / total) * 100;
  if (percentage < 19) {
    return GRADIENT.RED;
  }
  if (percentage < 39) {
    return GRADIENT.ORANGE;
  }
  if (percentage < 59) {
    return GRADIENT.YELLOW;
  }
  if (percentage < 79) {
    return GRADIENT.LESS_GREEN;
  }
  return GRADIENT.GREEN;
}

function addNullRatings(
  ratings: IPartialReviewRating[],
  question:
    | IUserReviewQuestionText
    | IUserReviewQuestionRating
    | IUserReviewQuestionCustomSkill
    | IUserReviewQuestionSkillCategory,
  userReview: IPopulatedUserReview,
) {
  const allRatings: IPartialReviewRating[] = [];
  const defaultRating = {
    userReviewQuestion: question.id,
    company: userReview.company,
    createdFor: userReview.createdFor,
    userReview: userReview.id,
    answer: question.type === REVIEW_QUESTION_TYPES.TEXT ? '' : -1,
    comment: null,
  };

  if (question.settings.evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER)) {
    userReview.peers.forEach((peer) => {
      const found = ratings.find((item) =>
        peer.type === USER_REVIEW_PEER_TYPE.USER
          ? item.createdBy.id === peer.value
          : item.createdBy.email === peer.value,
      );
      allRatings.push(
        found ?? {
          createdBy: {
            id: peer.type === USER_REVIEW_PEER_TYPE.USER ? peer.value : undefined,
            email: peer.type === USER_REVIEW_PEER_TYPE.EMAIL ? peer.value : undefined,
          },
          type:
            peer.type === USER_REVIEW_PEER_TYPE.USER
              ? REVIEW_RATING_TYPE.PEER
              : REVIEW_RATING_TYPE.PEER_EMAIL,
          ...defaultRating,
        },
      );
    });
  }

  if (question.settings.evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH)) {
    userReview.coaches.forEach((coach) => {
      const found = ratings.find((item) => item.createdBy.id === coach);
      allRatings.push(
        found ?? {
          createdBy: {
            id: coach,
          },
          type: REVIEW_RATING_TYPE.COACH,
          ...defaultRating,
        },
      );
    });
  }

  if (question.settings.evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)) {
    const found = ratings.find((item) => item.createdBy.id === userReview.createdFor);
    allRatings.push(
      found ?? {
        createdBy: {
          id: userReview.createdFor,
        },
        type: REVIEW_RATING_TYPE.SELF,
        ...defaultRating,
      },
    );
  }

  return allRatings;
}
